import Vue from 'vue'
import App from './App.vue'
import router from './router'
import './assets/css/tailwind.css'
import  {
  applyPolyfills,
  defineCustomElements
} from '@cit/citelis-footer/loader';

applyPolyfills().then(() => {
  defineCustomElements();
});

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
